import { clearAccessToken } from '@mosey/utils/auth';
import { api } from '../../utils/api';
import { redirect } from 'react-router-dom';

export const Logout = () => {
  return null;
};

Logout.loader = async () => {
  try {
    await api.post({
      url: '/api/handbook/auth/logout',
      credentials: 'include',
    });
  } catch (_e) {} // eslint-disable-line no-empty

  clearAccessToken();
  return redirect('/login');
};
